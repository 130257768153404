























































































































































.header-row{
  background:#e7ebf3;
  line-height: 40px;
  font-weight: bold;
}
.footer-row{
  background: #e7ebf3;
  color: #333;
  font-weight: bold;
  padding: 10px 0;
}
main{
  /*font-size: 1.5rem;*/
  background-color: #F1F4F8;
}
footer{
  left: 0;
  font-size: 1.25em;
  color: #edeef7;
  .bottom-footer{
    background: #474747;
  }
  a{
    color: inherit;
    cursor: pointer;
  }
}
